import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => (
  <Svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M54.6662 1.33334C53.8022 0.472003 52.4929 0.0693359 50.5462 0.0693359C46.6475 0.0693359 39.0022 1.90934 33.9249 6.98934L23.3915 17.52H5.6422C4.93553 17.52 4.25553 17.8027 3.7542 18.3013L1.86887 20.1893C0.828867 21.232 0.828867 22.92 1.86887 23.9627L32.0395 54.1307C32.5595 54.6507 33.2422 54.912 33.9249 54.912C34.6075 54.912 35.2902 54.6507 35.8102 54.1307L37.6982 52.2453C38.1995 51.744 38.4769 51.0667 38.4769 50.3573V32.6053L49.0102 22.072C52.8449 18.24 54.5355 13.2907 55.1995 10.688C55.7382 8.57334 56.7649 3.432 54.6662 1.33334ZM18.0609 22.8533L13.1835 27.7307L8.30353 22.8533H18.0609ZM16.9542 31.504L35.8102 12.648L43.3515 20.1867L24.4982 39.0453L16.9542 31.504ZM33.1462 47.6933L28.2689 42.8187L33.1462 37.9387V47.6933ZM46.9355 16.2293L39.7675 9.06134C43.5009 6.44 48.1222 5.40267 50.5462 5.40267C50.5729 5.40267 50.5969 5.40267 50.6209 5.40267C50.6529 7.552 49.6529 12.3573 46.9355 16.2293Z"
      fill="#231F20"
    />
    <path
      d="M16.9518 46.5893C15.7518 47.7867 11.1731 48.824 6.67444 49.3227C7.18377 44.808 8.23977 40.2133 9.41044 39.0453C10.0984 38.3547 10.9384 37.9173 11.8184 37.6827L7.79177 33.656C7.02911 34.104 6.29311 34.6187 5.63977 35.272C1.97844 38.936 1.21044 49.9333 1.09311 52.1013L0.933105 55.0667L3.90111 54.9067C6.06911 54.7893 17.0664 54.016 20.7251 50.3573C21.3811 49.7013 21.8931 48.968 22.3411 48.2027L18.3144 44.1733C18.0824 45.0613 17.6451 45.896 16.9518 46.5893Z"
      fill="#231F20"
    />
  </Svg>
);

export default Icon;
