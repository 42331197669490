import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4257_2101)">
        <path
          d="M45.6667 22.3333C42.96 22.3333 38.52 22.928 35 24.416V17C35 11.0133 24.0107 9 19 9C13.9893 9 3 11.0133 3 17V41C3 46.9867 13.9893 49 19 49C21.7227 49 26.2027 48.3973 29.7307 46.8933C30.4053 52.44 40.8187 54.3333 45.6667 54.3333C50.6773 54.3333 61.6667 52.32 61.6667 46.3333V30.3333C61.6667 24.3467 50.6773 22.3333 45.6667 22.3333ZM45.6667 27.6667C50.4267 27.6667 54.8347 29.2533 56.104 30.3333C54.832 31.416 50.424 33 45.6667 33C40.9093 33 36.4987 31.416 35.2293 30.3333C36.5013 29.2533 40.9067 27.6667 45.6667 27.6667ZM56.3333 38.072C55.5573 39.1413 50.832 41 45.6667 41C40.5013 41 35.776 39.1413 35 38.072V36.2533C38.52 37.7387 42.96 38.3333 45.6667 38.3333C48.3733 38.3333 52.8133 37.7387 56.3333 36.256V38.072ZM8.33333 30.9227C11.8533 32.4053 16.2933 33 19 33C21.7067 33 26.1467 32.4053 29.6667 30.9227V32.7413C28.8907 33.808 24.1653 35.6667 19 35.6667C13.8347 35.6667 9.10933 33.808 8.33333 32.7387V30.9227ZM8.33333 22.9227C11.8533 24.4053 16.2933 25 19 25C21.7067 25 26.1467 24.4053 29.6667 22.9227V24.7413C28.8907 25.808 24.1653 27.6667 19 27.6667C13.8347 27.6667 9.10933 25.808 8.33333 24.7387V22.9227ZM19 14.3333C23.76 14.3333 28.168 15.92 29.4373 17C28.168 18.0827 23.7573 19.6667 19 19.6667C14.2427 19.6667 9.83467 18.0827 8.56267 17C9.83467 15.92 14.24 14.3333 19 14.3333ZM8.33333 40.768V38.9227C11.8533 40.4053 16.2933 41 19 41C21.7067 41 26.1467 40.4053 29.6667 38.9227V40.768C28.8187 41.84 24.1093 43.6667 19 43.6667C13.8907 43.6667 9.18133 41.84 8.33333 40.768ZM45.6667 49C40.5573 49 35.848 47.1733 35 46.1013V44.256C38.52 45.7387 42.96 46.3333 45.6667 46.3333C48.3733 46.3333 52.8133 45.7387 56.3333 44.256V46.1013C55.4853 47.1733 50.776 49 45.6667 49Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4257_2101">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;
